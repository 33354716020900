import * as yup from "yup";
import { extractCurrency } from "../../../../../helpers";

import * as validationConst from "../../../../../helpers/validationConsts";
import {
  ERROR_EMAIL_FORMAT,
  // ERROR_REQUIRED,
} from "../../../../auth/pages/validationConsts";

const checkMinValue = (value) => {
  if (value !== null && value !== undefined) {
    return (
      Number(value.replace(validationConst.MONEY_REMOVE_SIGNS, "")) >=
      validationConst.MIN_DEAL_SIZE
    );
  }
  return value;
};

const checkMaxValue = (value) => {
  if (value !== null && value !== undefined) {
    return (
      Number(value.replace(validationConst.MONEY_REMOVE_SIGNS, "")) <=
      validationConst.MAX_DEAL_SIZE
    );
  }
  return value;
};

const dealInfoValidationSchema = yup.object({
  deal_name: yup
    .string()
    .required()
    .max(
      validationConst.MAX_DEAL_NAME,
      validationConst.ERROR_MESSAGE_MAX_DEAL_NAME
    )
    .min(validationConst.MIN_NAME, validationConst.ERROR_MESSAGE_MIN_NAME)
    .nullable(),
  deal_size: yup
    .string()
    .required(validationConst.ERROR_MESSAGE_REQUIRED)
    .matches(
      validationConst.MONEY_REGEXP,
      validationConst.ERROR_MESSAGE_MONEY_FORMAT
    )
    .test("deal_size", validationConst.ERROR_MESSAGE_DEAL_SIZE, (value) => {
      if (!!value) {
        return checkMinValue(value);
      }
      return true;
    })
    .test("deal_size", validationConst.ERROR_MESSAGE_MAX_DEAL_SIZE, (value) => {
      if (!!value) {
        return checkMaxValue(value);
      }
      return true;
    })
    .nullable(),
  policy_type: yup.string().nullable().oneOf(["buy_side", "sell_side", null]),
  policy_period: yup
    .string()
    .matches(
      validationConst.NUMBER_REGEXP_YEAR,
      validationConst.ERROR_MESSAGE_POLICY_NUMBER
    )
    .nullable()
    .test("policy_period", validationConst.ERROR_MESSAGE_MAX_YEAR, (value) => {
      if (!!value) {
        if (value > validationConst.MAX_POLICY_PERIOD) {
          return false;
        }
      }
      return true;
    })
    .transform((value) => (!!value ? value : null)),
  retention: yup
    .string()
    .notRequired()
    .matches(
      validationConst.MONEY_REGEXP,
      validationConst.ERROR_MESSAGE_MONEY_FORMAT
    )
    .nullable()
    .test(
      "retention",
      validationConst.ERROR_MESSAGE_MAX_RETENTION_VALUE,
      (value) => {
        if (!!value) {
          if (extractCurrency(value) > validationConst.MAX_RETENTION_VALUE) {
            return false;
          }
        }
        return true;
      }
    )
    /*  .test(
      "retention",
      validationConst.ERROR_MESSAGE_MIN_RETENTION_VALUE,
      (value) => {
        if (!!value) {
          if (extractCurrency(value) < validationConst.MIN_RETENTION_VALUE) {
            return false;
          }
        }
        return true;
      }
    ) */
    .transform((value) => (!!value ? value : null)),
  acquisition_type: yup
    .string()
    .notRequired()
    .nullable()
    .oneOf([
      "mergers",
      "acquisition",
      "consolidations",
      "tender_offers",
      "acquisition_of_assets",
      "management_acquisitions",
      null,
    ]),
  duty_to_defend: yup.bool().nullable().oneOf([true, false, null]),
  intent_date_letter: yup.bool().nullable().oneOf([true, false, null]),
  sale_agreement_type: yup.string().nullable().oneOf(["draft", "final", null]),
  sale_agreement_date: yup.date().nullable().default(null),
  // .max(
  //   validationConst.MAX_PURCHASE_AGREEMENT_DATE,
  //   validationConst.ERROR_MESSAGE_MAX_DATE
  // )
  // .when("sale_agreement_type", {
  //   is: "draft",
  //   then: yup
  //     .date()
  //     .nullable()
  //     .default(null)
  //     .min(
  //       validationConst.MIN_PURCHASE_AGREEMENT_DATE_DRAFT,
  //       validationConst.ERROR_MESSAGE_MIN_DATE2
  //     ),
  //   otherwise: yup
  //     .date()
  //     .nullable()
  //     .default(null)
  //     .min(
  //       validationConst.MIN_PURCHASE_AGREEMENT_DATE_FINAL,
  //       validationConst.ERROR_MESSAGE_MIN_DATE2
  //     ),
  // }),
  integration_lead_present: yup.bool().nullable(),
  integration_lead_first_name: yup.string().when("integration_lead_present", {
    is: true,
    then: yup
      .string()
      .nullable()
      .notRequired()
      // .required(validationConst.ERROR_MESSAGE_REQUIRED)
      .max(validationConst.MAX_NAME, validationConst.ERROR_MESSAGE_MAX_NAME)
      // .min(validationConst.MIN_NAME, validationConst.ERROR_MESSAGE_MIN_NAME)
      .matches(validationConst.LETTERS_NUMBERS_REGEXP, {
        message: validationConst.ERROR_MESSAGE_LETTERS_NUMBERS,
      }),
  }),
  integration_lead_last_name: yup.string().when("integration_lead_present", {
    is: true,
    then: yup
      .string()
      .notRequired()
      .nullable()
      // .required(validationConst.ERROR_MESSAGE_REQUIRED)
      .max(validationConst.MAX_NAME, validationConst.ERROR_MESSAGE_MAX_NAME)
      // .min(validationConst.MIN_NAME, validationConst.ERROR_MESSAGE_MIN_NAME)
      .matches(validationConst.LETTERS_NUMBERS_REGEXP, {
        message: validationConst.ERROR_MESSAGE_LETTERS_NUMBERS,
      }),
  }),
  integration_lead_email: yup.string().when("integration_lead_present", {
    is: true,
    then: yup
      .string()
      .nullable()
      // .required(ERROR_REQUIRED)
      .notRequired()
      .email(ERROR_EMAIL_FORMAT),
  }),
  external_data_room_count: yup
    .string()
    .nullable()
    .oneOf(["0", "1", "2", null]),
  external_data_room_url_first: yup
    .string()
    .nullable()
    .when("external_data_room_count", {
      is: "1",
      then: yup
        .string()
        .required(validationConst.ERROR_MESSAGE_REQUIRED)
        .nullable()
        .matches(validationConst.URL_REGEXP, {
          message: validationConst.ERROR_MESSAGE_URL_FORMAT,
        }),
    })
    .when("external_data_room_count", {
      is: "2",
      then: yup
        .string()
        .nullable()
        .required(validationConst.ERROR_MESSAGE_REQUIRED)
        .matches(validationConst.URL_REGEXP, {
          message: validationConst.ERROR_MESSAGE_URL_FORMAT,
        })
        .typeError(validationConst.ERROR_MESSAGE_REQUIRED),
    }),
  external_data_room_url_second: yup
    .string()
    .nullable()
    .when("external_data_room_count", {
      is: "2",
      then: yup
        .string()
        .required(validationConst.ERROR_MESSAGE_REQUIRED)
        .matches(validationConst.URL_REGEXP, {
          message: validationConst.ERROR_MESSAGE_URL_FORMAT,
        })
        .typeError(validationConst.ERROR_MESSAGE_REQUIRED),
    }),
  organization_chart_file_present: yup.bool().nullable(),
});

export default dealInfoValidationSchema;
