import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { QMSelectInput, QMTextInput } from "../../../common/QMInputs";

const IntegrationLeadInfoBlock = ({
  register,
  control,
  watch,
  getFieldErrors,
  boolOpts,
  setShowIntegrationLeadWarning,
  setValue,
}) => {
  const { t } = useTranslation();
  const integrationLeadWatch = watch("integration_lead_present");

  useEffect(() => {
    if (!integrationLeadWatch) {
      setValue("integration_lead_first_name", "");
      setValue("integration_lead_last_name", "");
      setValue("integration_lead_email", "");
    }
  }, [integrationLeadWatch, setValue]);

  const handleSelectChange = (val, field) => {
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
    if (val) setShowIntegrationLeadWarning(val.value);
  };
  return (
    <section className="QM-formBlock">
      <section className="QM-formRow">
        <Controller
          render={({ field }) => (
            <QMSelectInput
              {...field}
              placeholder={t("general.choose_answer")}
              label={t("new_deal_form.integration_lead")}
              errors={getFieldErrors("integration_lead_present")}
              options={boolOpts}
              value={boolOpts.find((c) => c.value === field.value)}
              onChange={(val) => handleSelectChange(val, field)}
              isClearable
            />
          )}
          control={control}
          name="integration_lead_present"
        />
      </section>
      <section className="QM-formRow">
        <QMTextInput
          {...register("integration_lead_first_name")}
          label={t("new_deal_form.integration_lead_first_name")}
          placeholder={t("new_deal_form.integration_lead_first_name")}
          errors={getFieldErrors("integration_lead_first_name")}
          disabled={integrationLeadWatch !== true}
        />
        <QMTextInput
          {...register("integration_lead_last_name")}
          label={t("new_deal_form.integration_lead_last_name")}
          placeholder={t("new_deal_form.integration_lead_last_name")}
          errors={getFieldErrors("integration_lead_last_name")}
          disabled={integrationLeadWatch !== true}
        />
        <QMTextInput
          {...register("integration_lead_email")}
          label={t("new_deal_form.integration_lead_email")}
          placeholder={t("new_deal_form.integration_lead_email")}
          errors={getFieldErrors("integration_lead_email")}
          disabled={integrationLeadWatch !== true}
        />
      </section>
    </section>
  );
};

export default IntegrationLeadInfoBlock;
