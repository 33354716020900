import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import useLeavePageBlocker from "../../../../../helpers/useLeavePageBlocker";
import { DEAL_DATE_FORMAT } from "../../../../../helpers/broker-helpers";
// import {
//   MAX_PURCHASE_AGREEMENT_DATE,
//   MIN_PURCHASE_AGREEMENT_DATE_DRAFT,
//   MIN_PURCHASE_AGREEMENT_DATE_FINAL,
// } from "../../../../../helpers/validationConsts";
import {
  QMCurrencyInput,
  QMDateInput,
  QMSelectInput,
  QMTextInput,
  QMTextarea,
} from "../../../common/QMInputs";
import QMSelectAcquisition from "../../../common/QMInputs/QMSelectAcquisition";
import QMSaveLayout from "../../../common/QMSaveLayout";
import QMToastMessage from "../../../common/QMToastMessage";
import dealInfoValidationSchema from "./dealInfoValidationSchema";
import { putDealInfoTabInfo } from "../../../../../api/dealInfoTabApi";
import { dealInfoTabSelector } from "../../../../../redux/selectors/deal-info-tab-selector";

import "./styles.scss";

const DealInfoOverlay = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dealInfo = useSelector(dealInfoTabSelector);
  const boolOpts = [
    { value: true, label: t("general.yes") },
    { value: false, label: t("general.no") },
  ];

  const saleAgreementOpt = [
    { value: "draft", label: t("general.draft") },
    { value: "final", label: t("general.final") },
  ];

  const policyTypeOpt = [
    { value: "buy_side", label: t("deal_info.buy_side") },
    { value: "sell_side", label: t("deal_info.sell_side") },
  ];

  const externalDataRoomAccessOpts = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
  ];
  const saleAcquisitionType = [
    "mergers",
    "acquisition",
    "consolidations",
    "tender_offers",
    "acquisition_of_assets",
    "management_acquisitions",
  ].map((type) => ({
    value: type,
    label: t(`deal_info.sale_acquisition_types.${type}.label`),
    content: t(`deal_info.sale_acquisition_types.${type}.description`),
  }));
  const {
    handleSubmit,
    clearErrors,
    getValues,
    watch,
    control,
    register,
    setError,
    setValue,
    reset,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(dealInfoValidationSchema),
    mode: "onChange",
  });
  const [showIntegrationLeadWarning, setShowIntegrationLeadWarning] =
    useState(null);
  const [showOrgChartWarning, setShowOrgChartWarning] = useState(null);
  useLeavePageBlocker(isDirty);

  const onSubmit = (data) => {
    putDealInfoTabInfo(dispatch, id, data, setError);
  };

  const getFieldErrors = (fieldName) => {
    return errors[fieldName];
  };
  // const saleAgreementTypeWatch = watch("sale_agreement_type");
  const integrationLeadWatch = watch("integration_lead_present");
  const externalDataRoomAccessWatch = watch("external_data_room_count");
  // const minPurchaseDate =
  //   saleAgreementTypeWatch === "draft"
  //     ? MIN_PURCHASE_AGREEMENT_DATE_DRAFT
  //     : MIN_PURCHASE_AGREEMENT_DATE_FINAL;

  const handleExternalDataRoomCountChange = (val, field) => {
    if (val === null) {
      clearErrors("external_data_room_url_first", null);
      clearErrors("external_data_room_url_second", null);
      setValue("external_data_room_url_first", null);
      setValue("external_data_room_url_second", null);
    }
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
  };

  useEffect(() => {
    if (dealInfo && Object.keys(dealInfo).length) {
      Object.keys(dealInfo).forEach((key) => {
        if (key === "sale_agreement_date") {
          const val = dealInfo[key] ? new Date(dealInfo[key]) : null;
          setValue(key, val);
        } else if (
          (key === "deal_description" && !dealInfo[key]) ||
          (key === "integration_lead_first_name" && !dealInfo[key]) ||
          (key === "integration_lead_last_name" && !dealInfo[key]) ||
          (key === "integration_lead_email" && !dealInfo[key])
        ) {
          setValue(key, "");
        } else {
          setValue(key, dealInfo[key]);
        }
      });
    }
  }, [dealInfo, setValue]);

  const handleSelectChange = (val, field, additionalHandler) => {
    const updatedVal = val === null ? val : val.value;
    field.onChange(updatedVal);
    if (additionalHandler) {
      additionalHandler(updatedVal);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(undefined, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    if (!integrationLeadWatch) {
      setValue("integration_lead_first_name", "");
      setValue("integration_lead_last_name", "");
      setValue("integration_lead_email", "");
    }
    if (externalDataRoomAccessWatch === 1) {
      setValue("external_data_room_url_second", null);
    }
  }, [integrationLeadWatch, externalDataRoomAccessWatch, setValue]);

  const getContent = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="deal-info-tab__row">
            <QMTextInput
              {...register("deal_name")}
              label={t("deal_info.deal_project_name")}
              placeholder={t("deal_info.deal_project_name_placeholder")}
              errors={getFieldErrors("deal_name")}
              isRequired
            />
            <Controller
              render={({ field }) => (
                <QMCurrencyInput
                  {...field}
                  decimalScale={0}
                  label={t("deal_info.deal_size_enterprise")}
                  placeholder={t("general.currency_placeholder")}
                  errors={getFieldErrors("deal_size")}
                  isRequired
                />
              )}
              control={control}
              name="deal_size"
            />
          </section>
          <section className="deal-info-tab__row">
            <Controller
              render={({ field }) => (
                <QMSelectInput
                  {...field}
                  placeholder={t("general.select")}
                  label={t("deal_info.policy_type")}
                  errors={getFieldErrors("policy_type")}
                  options={policyTypeOpt}
                  value={policyTypeOpt.find((c) => c.value === field.value)}
                  onChange={(val) => handleSelectChange(val, field)}
                  isClearable
                />
              )}
              control={control}
              name="policy_type"
            />
            <QMTextInput
              {...register("policy_period")}
              label={t("deal_info.policy_period_requested")}
              placeholder={t("general.enter_number_of_years")}
              errors={getFieldErrors("policy_period")}
            />
          </section>
          <section className="deal-info-tab__row">
            <Controller
              render={({ field }) => (
                <QMCurrencyInput
                  {...field}
                  decimalScale={0}
                  label={t("deal_info.retention_requested")}
                  placeholder={t("general.currency_placeholder")}
                  errors={getFieldErrors("retention")}
                />
              )}
              control={control}
              name="retention"
            />
            <Controller
              render={({ field }) => (
                <QMSelectAcquisition
                  {...field}
                  placeholder={t("general.select")}
                  label={t("deal_info.sale_acquisition_type")}
                  errors={getFieldErrors("acquisition_type")}
                  options={saleAcquisitionType}
                  value={saleAcquisitionType.find(
                    (c) => c.value === field.value
                  )}
                  onChange={(val) => handleSelectChange(val, field)}
                  isClearable
                  saleAcquisitionToolTip
                />
              )}
              control={control}
              name="acquisition_type"
            />
          </section>
          <section className="deal-info-tab__row">
            <Controller
              render={({ field }) => (
                <QMSelectInput
                  {...field}
                  placeholder={t("general.select")}
                  label={t("deal_info.duty_to_defend_requested")}
                  errors={getFieldErrors("duty_to_defend")}
                  options={boolOpts}
                  value={boolOpts.find((c) => c.value === field.value)}
                  onChange={(val) => handleSelectChange(val, field)}
                  isClearable
                />
              )}
              control={control}
              name="duty_to_defend"
            />
            <Controller
              render={({ field }) => (
                <QMSelectInput
                  {...field}
                  placeholder={t("general.select")}
                  label={t("deal_info.letter_of_intent_date")}
                  errors={getFieldErrors("intent_date_letter")}
                  options={boolOpts}
                  value={boolOpts.find((c) => c.value === field.value)}
                  onChange={(val) => handleSelectChange(val, field)}
                  isClearable
                />
              )}
              control={control}
              name="intent_date_letter"
            />
          </section>
          <section className="deal-info-tab__row">
            <Controller
              render={({ field }) => (
                <QMSelectInput
                  {...field}
                  placeholder={t("general.select")}
                  label={t("deal_info.sale_agreement")}
                  errors={getFieldErrors("sale_agreement_type")}
                  options={saleAgreementOpt}
                  value={saleAgreementOpt.find((c) => c.value === field.value)}
                  onChange={(val) => handleSelectChange(val, field)}
                  isClearable
                />
              )}
              control={control}
              name="sale_agreement_type"
            />
            <Controller
              render={({ field }) => (
                <QMDateInput
                  {...field}
                  placeholder={t("general.select_date")}
                  label={t("deal_info.purchase_sale_agreement_date")}
                  errors={getFieldErrors("sale_agreement_date")}
                  format={DEAL_DATE_FORMAT}
                  // minDate={minPurchaseDate}
                  // maxDate={MAX_PURCHASE_AGREEMENT_DATE}
                  clearErrors={clearErrors}
                  setError={setError}
                  getValues={getValues}
                />
              )}
              control={control}
              name="sale_agreement_date"
            />
          </section>
          <section className="deal-info-tab__row">
            <Controller
              render={({ field }) => (
                <QMSelectInput
                  {...field}
                  placeholder={t("general.choose_answer")}
                  label={t("new_deal_form.integration_lead")}
                  errors={getFieldErrors("integration_lead_present")}
                  options={boolOpts}
                  value={boolOpts.find((c) => c.value === field.value)}
                  onChange={(val) => {
                    handleSelectChange(
                      val,
                      field,
                      setShowIntegrationLeadWarning
                    );
                  }}
                  isClearable
                />
              )}
              control={control}
              name="integration_lead_present"
            />
          </section>
          <section className="deal-info-tab__row">
            <QMTextInput
              {...register("integration_lead_first_name")}
              label={t("new_deal_form.integration_lead_first_name")}
              placeholder={t("new_deal_form.integration_lead_first_name")}
              errors={
                integrationLeadWatch &&
                getFieldErrors("integration_lead_first_name")
              }
              disabled={!integrationLeadWatch}
              // isRequired={integrationLeadWatch}
            />
            <QMTextInput
              {...register("integration_lead_last_name")}
              label={t("new_deal_form.integration_lead_last_name")}
              placeholder={t("new_deal_form.integration_lead_last_name")}
              errors={
                integrationLeadWatch &&
                getFieldErrors("integration_lead_last_name")
              }
              disabled={!integrationLeadWatch}
              // isRequired={integrationLeadWatch}
            />
            <QMTextInput
              {...register("integration_lead_email")}
              label={t("new_deal_form.integration_lead_email")}
              placeholder={t("new_deal_form.integration_lead_email")}
              errors={getFieldErrors("integration_lead_email")}
              disabled={!integrationLeadWatch}
              // isRequired={integrationLeadWatch}
            />
          </section>
          <section className="deal-info-tab__row">
            <Controller
              render={({ field }) => (
                <QMSelectInput
                  placeholder={t("general.choose_answer")}
                  label={t("new_deal_form.external_data_room_access_label")}
                  errors={getFieldErrors("external_data_room_count")}
                  options={externalDataRoomAccessOpts}
                  value={externalDataRoomAccessOpts.find(
                    (c) => c.value === field.value
                  )}
                  ref={field.ref}
                  onChange={(val) => {
                    handleExternalDataRoomCountChange(val, field);
                  }}
                  isClearable
                />
              )}
              control={control}
              name="external_data_room_count"
            />
            <QMTextInput
              {...register("external_data_room_url_first")}
              label={t("new_deal_form.secure_url_to_data_room_1")}
              placeholder={t("general.url_placeholder")}
              errors={getFieldErrors("external_data_room_url_first")}
              disabled={
                externalDataRoomAccessWatch !== 1 &&
                externalDataRoomAccessWatch !== 2
              }
              isRequired={
                externalDataRoomAccessWatch === 1 ||
                externalDataRoomAccessWatch === 2
              }
            />
            <QMTextInput
              {...register("external_data_room_url_second")}
              label={t("new_deal_form.secure_url_to_data_room_2")}
              placeholder={t("general.url_placeholder")}
              errors={getFieldErrors("external_data_room_url_second")}
              disabled={externalDataRoomAccessWatch !== 2}
              isRequired={externalDataRoomAccessWatch === 2}
            />
          </section>
          <section className="deal-info-tab__row">
            <Controller
              render={({ field }) => (
                <QMSelectInput
                  {...field}
                  placeholder={t("general.choose_answer")}
                  label={t("new_deal_form.org_chart")}
                  errors={getFieldErrors("organization_chart_file_present")}
                  options={boolOpts}
                  value={boolOpts.find((c) => c.value === field.value)}
                  onChange={(val) => {
                    handleSelectChange(val, field, setShowOrgChartWarning);
                  }}
                  isClearable
                />
              )}
              control={control}
              name="organization_chart_file_present"
            />
          </section>
          <section className="deal-info-tab__textarea">
            <Controller
              render={({ field }) => (
                <QMTextarea
                  {...field}
                  label={t("deal_info.transaction_description")}
                  placeholder={t("deal_info.transaction_description")}
                  errors={getFieldErrors("deal_description")}
                  withCount
                  maxLength={300}
                />
              )}
              control={control}
              name="deal_description"
            />
          </section>

          {showIntegrationLeadWarning === false && (
            <QMToastMessage
              text={t("new_deal_form.integration_lead_warning")}
              viewType="warning"
              iconClassName="icon-attention"
              onClose={() => setShowIntegrationLeadWarning(null)}
            />
          )}
          {showOrgChartWarning === false && (
            <QMToastMessage
              text={t("new_deal_form.org_chart_warning")}
              viewType="warning"
              iconClassName="icon-attention"
              onClose={() => setShowOrgChartWarning(null)}
            />
          )}
          {isDirty && <QMSaveLayout />}
        </form>
      </>
    );
  };
  return <>{getContent()}</>;
};

export default DealInfoOverlay;
