import { REQUEST_DATE_FORMAT, parseDate, formatDate } from ".";

const DEAL_DATE_FORMAT = "d MMM yyyy";
const SELF_CLOSE_DELAY = 2500;

const formatCurrency = (num) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  return formatter.format(num);
};

const reformatDealDate = (dealDateStr) => {
  const parsedDealDate = parseDate(dealDateStr, REQUEST_DATE_FORMAT);
  return formatDate(parsedDealDate, DEAL_DATE_FORMAT);
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const reformatSubmitDate = (dealSubmitDate) => {
  const dealDate = new Date(dealSubmitDate);

  const formatDate = dealDate.toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const date = `${formatDate}`;

  return date;
};

const createAutocompleteRef = (
  inputValue,
  inputName,
  selectedOpt,
  optSetter,
  focusSetter,
  setValue
) => {
  focusSetter(false);
  if (inputValue) {
    // setValue(inputName, selectedOpt.business_name);
  } else {
    optSetter(null);
  }
};

export {
  DEAL_DATE_FORMAT,
  SELF_CLOSE_DELAY,
  formatCurrency,
  reformatDealDate,
  capitalizeFirstLetter,
  reformatSubmitDate,
  createAutocompleteRef,
};
